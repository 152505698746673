import axios from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { Faq } from "@app/types";
import { cacheKeys } from "@app/constants/cacheKeys";

const fetchFaqs = async () => {
  const request = await axios.get(`/data/faq.json`);
  return request.data;
};

const useFaqs = (): UseQueryResult<Faq[]> =>
  useQuery({
    queryKey: cacheKeys.faqs,
    queryFn: fetchFaqs,
    refetchOnWindowFocus: true
  });

export { useFaqs };
