import React, { useEffect } from "react";

interface Bubbles {
  x: number;
  y: number;
  r: number;
  dx: number;
  dy: number;
  alive: boolean;
}

const canvasAnimation = () => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  window.onresize = (event) => {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  };

  let bubbles: Bubbles[] = [];

  const drawArc = () => {
    context.beginPath();
    context.arc(canvas.width / 2, canvas.height * 2, 1100, 0, 2 * Math.PI);
    context.fillStyle = "white";
    context.fill();
    context.strokeStyle = "white";
    context.stroke();
    context.closePath();
  };

  const draw = (x: number, y: number, r: number) => {
    context.beginPath();
    context.arc(x, y, r, 0, 2 * Math.PI);
    context.fillStyle = "white";
    context.fill();
    context.strokeStyle = "white";
    context.stroke();
    context.closePath();
  };

  const drawLines = () => {
    bubbles.map((b, index) => {
      if (!b.alive) return;
      context.beginPath();
      context.moveTo(b.x - b.r / 2, b.y - b.r / 2);
      if (bubbles[index + 1]) {
        const bub = bubbles[index + 1];
        context.lineTo(bub.x, bub.y);
      }
      context.stroke();
    });
  };

  const handleAddCircle = ({
    pageX: x,
    pageY: y
  }: {
    pageX: number;
    pageY: number;
  }) => {
    const newBubble = {
      x,
      y,
      r: Math.random() * 20 + 2,
      dx: Math.random() * 2 - 1,
      dy: -Math.random() * 5 - 1,
      alive: true
    };
    bubbles = [newBubble, ...bubbles];
  };

  const animate = () => {
    context.clearRect(0, 0, canvas.width, canvas.height);

    drawArc();
    drawLines();

    if (Math.random() < 0.1) {
      handleAddCircle({
        pageX: Math.random() * canvas.width,
        pageY: canvas.height + 50
      });
    }

    bubbles.map((b) => {
      if (b.y < -(b.r * 2)) b.alive = false;
      if (!b.alive) return;
      b.x += b.dx;
      b.y += b.dy;
      // b.r += Math.abs(b.dx) / 2;
      draw(b.x, b.y, b.r);
    });
    requestAnimationFrame(animate);
  };

  canvas.addEventListener("click", handleAddCircle);

  animate();
};

const Canvas = () => {
  useEffect(() => {
    canvasAnimation();
  }, []);

  return <canvas id="canvas"></canvas>;
};

export { Canvas };
