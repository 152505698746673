import React from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import { Key } from "@app/components/Icons";

const Layout = () => (
  <>
    <header>
      <Link to="/">
        <img src="./images/logo_buymelunch.png" width="211" height="107" />
      </Link>
      <nav className="nav">
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/faqs">Faqs</NavLink>
          </li>
          <li>
            <NavLink to="/terms">Terms</NavLink>
          </li>
          <li>
            <NavLink to="/privacy">Privacy</NavLink>
          </li>
          <li>
            <NavLink to="/admin" className="loginButton">
              <Key scale={0.7} />
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
    <Outlet />
  </>
);

export { Layout };
