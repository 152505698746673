import React from "react";
import { useUserCount } from "@app/utils/query";

const Stat = ({ label, isLoading, children }) => (
  <div className="stat stat--number">
    <div className="stat_number">{children}</div>
    {label}
  </div>
);

const Admin = () => {
  const { data, isLoading } = useUserCount();

  return (
    <section className="content">
      <div className="wrap">
        <h1>Dashboard</h1>
        <section className="dash">
          <Stat label="Users" isLoading={isLoading}>
            {data && data.userCount}
          </Stat>
        </section>
      </div>
    </section>
  );
};

export { Admin };
