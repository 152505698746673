import React from "react";
import Markdown from "react-markdown";

import { Spinner } from "@app/components";
import { useRemoteMarkdown } from "@app/utils/query";

const Privacy = () => {
  const { data, isLoading } = useRemoteMarkdown("privacy");

  return (
    <section className="content">
      <div className="wrap">
        {isLoading && <Spinner />}
        {data && <Markdown>{data}</Markdown>}
      </div>
    </section>
  );
};

export { Privacy };
