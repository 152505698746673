import axios from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { cacheKeys } from "@app/constants/cacheKeys";

const useRemoteMarkdown = (
  variant: "terms" | "privacy"
): UseQueryResult<string> => {
  const url = {
    terms: "/data/terms.md",
    privacy: "/data/privacy.md"
  }[variant];

  const fetchMarkdown = async () => {
    const request = await axios.get(url);
    return request.data;
  };

  return useQuery({
    queryKey: [cacheKeys.md[0], variant],
    queryFn: fetchMarkdown,
    refetchOnWindowFocus: true
  });
};

export { useRemoteMarkdown };
