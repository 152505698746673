import React from "react";
import Markdown from "react-markdown";

import { Spinner } from "@app/components";
import { useFaqs } from "@app/utils/query/fetchFaq";

const Faqs = () => {
  const { data: data, isLoading, isSuccess } = useFaqs();
  const gotFaqs = isSuccess && data && data?.length > 0;

  return (
    <section className="content">
      <div className="wrap">
        <h1>Faqs</h1>
        {isLoading && <Spinner />}
        {gotFaqs &&
          data.map((item, i) => (
            <div key={i}>
              <h2>{item.title}</h2>
              <Markdown>{item.content}</Markdown>
            </div>
          ))}
      </div>
    </section>
  );
};

export { Faqs };
