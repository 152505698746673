import React from "react";
import { Canvas } from "./Canvas";

const Home = () => (
  <>
    <Canvas />
    <section className="content">
      <img
        src="./images/phone1a.png"
        width="331"
        height="595"
        className="phone"
      />
      <div className="wrap">
        <h1>Buy Me Lunch</h1>
        <p>
          BuyMeLunch is your gateway to forging connections over great food!
          Create your profile, set your lunch intentions, and let the delicious
          journey begin. Whether you're craving sushi in the city or a cozy café
          in the suburbs, find your perfect lunchtime companion with BuyMeLunch.
        </p>
        <section className="section-features">
          <h2>Key Features</h2>
          <div className="section-features__wrap">
            <div>
              <h3>Create Your Lunch Profile</h3>
              <p>
                Share your culinary preferences, preferred dining areas, and
                availability to let others know when you're up for lunch.
              </p>
            </div>
            <div>
              <h3>Discover Lunch Buddies</h3>
              <p>
                Receive invites tailored to your lunch intentions and
                preferences. Say goodbye to solo lunches and hello to shared
                culinary adventures.
              </p>
            </div>
            <div>
              <h3>Connect and Chat</h3>
              <p>
                Strike up conversations with potential lunch buddies within the
                app. Coordinate details, discuss favorite eateries, and plan
                your lunch outing effortlessly.
              </p>
            </div>
            <div>
              <h3>Stay Safe</h3>
              <p>
                Rest easy knowing that BuyMeLunch prioritizes user safety and
                privacy. Enjoy peace of mind while connecting with like-minded
                individuals over lunch.
              </p>
            </div>
            <div>
              <h3>Join the BuyMeLunch Community</h3>
              <p>
                Be part of a vibrant community of food lovers eager to explore
                new flavours and forge meaningful connections. Share dining
                recommendations, swap stories, and make lunchtime unforgettable.
              </p>
            </div>
          </div>
        </section>
        <a href="#" className="button button--large _flex _mt-20 _mb-20">
          Download Buy Me Lunch
        </a>
      </div>
      <p className="_centerText _mt-20">
        <b>Hello Invent Ltd</b>
        <br />
        Company Number: 09342309
        <br />
        VAT Number: 424035532
        <br />
        <br />
        Address
        <br />
        80B Mildmay Park
        <br />
        London <br />
        N1 4PR <br />
        GB
        <br />
      </p>
    </section>
  </>
);

export { Home };
