import axios from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { ADMIN_RERESH_MS, API_URL, cacheKeys } from "@app/constants";

const fetchUserCount = async () => {
  const request = await axios.get(`${API_URL}/users/count`);
  return request.data;
};

const useUserCount = (): UseQueryResult<any> =>
  useQuery({
    queryKey: cacheKeys.userCount,
    queryFn: fetchUserCount,
    refetchOnWindowFocus: true,
    refetchInterval: ADMIN_RERESH_MS
  });

export { useUserCount };
